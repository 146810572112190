import { trpc } from '@api/client';

export const useGetDeal = (source?: string) => {
  const getDealById = trpc.getDealById.useMutation({
    trpc: { context: { useCognitoToken: true } },
  });
  const getAcquisitionDealById = trpc.getAcquisitionDealByIdRouter.useMutation({
    trpc: { context: { useCognitoToken: true } },
  });
  const getBasicDealDataByHubspotId =
    trpc.getBasicDealDataByHubspotId.useMutation();

  const getDealData = async (
    input: { dealId: string } | { hubspotId: string },
  ) => {
    if ('dealId' in input) {
      return source === 'acquisition'
        ? getAcquisitionDealById.mutateAsync(input)
        : getDealById.mutateAsync(input);
    }

    return getBasicDealDataByHubspotId.mutateAsync({
      dealId: input.hubspotId,
    });
  };

  return {
    getDealData,
    isLoading: getDealById.isLoading || getBasicDealDataByHubspotId.isLoading,
    error: getDealById.error || getBasicDealDataByHubspotId.error,
  };
};
