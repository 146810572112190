import { useEffect, useRef, useState } from 'react';
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useBasicDealData } from './hooks/use-basic-deal-data';
import {
  Button,
  Divider,
  Icons,
  LinkButton,
  IconsProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Content,
  ContentContainer,
  Footer,
  FooterButtons,
  FooterContainer,
  Header,
  HeaderContainer,
  ImageColumn,
  Image,
  MainContainer,
} from './signup.styles';
import { FinishLaterModal, PageFooter, SignUpSuccessModal } from '@/components';

import BannerImage from '../../assets/banner.png';
import LogoFlash from '../../assets/logo_flash.svg';
import { useCompanyInfoForm } from './steps/CompanyInfo/company-info.hooks';
import { useAdminInfoForm } from './steps/AdminInfo/admin-info.hooks';
import { FluxEndModal } from './steps/PhoneAuthentication/FluxEndModal';
import { useCommercialConditionsForm } from './steps/ComercialConditions/commercial-conditions.hooks';
import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import {
  AdminInfo,
  ComercialConditions,
  CompanyInfo,
  PersonalInfo,
  PhoneAuthentication,
  SignUpErrorPage,
} from './steps';
import SignUpProgressBar from './components/SignupProgressBar';
import { SignupType } from './signup.constants';
import { useGetDeal } from './hooks/use-get-deal';

const bannerUrl =
  'https://images.flashapp.com.br/flash-os/signup/signup_banner.png';

interface SignupProps {
  signupType: SignupType;
}

export const SignUp = ({ signupType }: SignupProps) => {
  const refToTop = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { companyId, contractId, dealId, leadId, step } = useParams();
  const { getDealData, error } = useGetDeal(searchParams.get('source') ?? '');
  const { authenticatedUser, loading: userLoading } =
    useCognitoAuthenticatedUser();

  const [registrationNumber, setRegistrationNumber] = useState('');
  const [hasBenefitsProduct, setHasBenefitsProduct] = useState(false);
  const [openSignupSuccessModal, setOpenSignupSuccessModal] = useState(false);
  const [openFluxEndModal, setOpenFluxEndModal] = useState(false);
  const [openFinishLaterModal, setOpenFinishLaterModal] = useState(false);

  const dealIdV2 = searchParams.get('dealId');
  const isSales = !!(companyId && contractId);

  const onFinishLater = () => setOpenFinishLaterModal(true);
  const onCompanySignupSuccess = (contractId?: string) => {
    if (contractId) {
      navigate({ search: createSearchParams({ contractId }).toString() });
    }

    if (hasBenefitsProduct || signupType === 'self') {
      setOpenSignupSuccessModal(true);
    } else {
      setOpenFluxEndModal(true);
    }
  };

  const onSignupSuccessConfirm = () => {
    setOpenSignupSuccessModal(false);

    navigate({
      pathname: dealId
        ? `/signup/hubspot/${dealId}/commercial-conditions`
        : `/signup/self/commercial-conditions`,
      search: searchParams.toString(),
    });
  };

  const onBackFromAdminInfo = () => {
    if (isSales)
      return navigate(`/signup/sales/create-admin/${companyId}/${contractId}`);

    if (dealId)
      return navigate(
        `/signup/hubspot/${dealId}/create-company?${searchParams.toString()}`,
      );

    if (leadId) return navigate(`/signup/hubspot/${leadId}/create-company`);

    navigate({
      pathname: '/signup/self/create-company',
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const getDeal = async () => {
      if (dealId) {
        const data = await getDealData(
          dealIdV2 ? { dealId: dealIdV2 } : { hubspotId: dealId },
        );
        setRegistrationNumber(data.registrationNumber);
        setHasBenefitsProduct(data.hasBenefitsProduct);
      }
    };

    getDeal();
  }, []);

  useEffect(
    () => refToTop.current?.scrollIntoView({ behavior: 'smooth' }),
    [step],
  );

  const { form: companyInfoForm } = useCompanyInfoForm({
    companyId,
    contractId,
    dealId,
    isSales,
    leadId,
  });

  const { form: adminInfoForm, isLoading: adminInfoLoading } = useAdminInfoForm(
    {
      signupType,
      dealId,
      leadId,
      onSuccess: onCompanySignupSuccess,
    },
  );

  const {
    form: commercialConditionsForm,
    isLoading: commercialConditionsLoading,
  } = useCommercialConditionsForm({
    signupType,
    dealId: dealId!,
    onSuccess: () => setOpenFluxEndModal(true),
  });

  useEffect(() => {
    if (!authenticatedUser || userLoading) return;
    adminInfoForm.setValues({
      email: authenticatedUser.attributes.email,
      name: authenticatedUser.attributes?.name || '',
      documentNumber: authenticatedUser.attributes.preferred_username,
      phone: authenticatedUser.attributes.phone_number?.slice(3),
    });
  }, [authenticatedUser, userLoading]);

  const getStep = () => {
    switch (step) {
      case 'create-company':
        return {
          component: (
            <CompanyInfo
              form={companyInfoForm}
              dealId={dealId}
              companyId={companyId}
              registrationNumber={registrationNumber}
            />
          ),
          form: companyInfoForm,
          useNewLayout: true,
          image: bannerUrl,
          index: 1,
        };
      case 'create-admin':
        return {
          component: (
            <PersonalInfo
              isSales={isSales}
              dealId={dealId}
              companyId={companyId}
              contractId={contractId}
              leadId={leadId}
              signupType={signupType}
            />
          ),
          image: BannerImage,
          index: 2,
        };
      case 'admin-info':
        return {
          component: (
            <AdminInfo
              form={adminInfoForm}
              isSales={isSales}
              dealId={dealId}
              companyId={companyId}
              contractId={contractId}
              leadId={leadId}
              authenticatedUser={authenticatedUser}
              loading={userLoading}
            />
          ),
          form: adminInfoForm,
          onBack: onBackFromAdminInfo,
          useNewLayout: true,
          image: bannerUrl,
          index: 2,
        };
      case 'validate-phone':
        return {
          component: (
            <PhoneAuthentication
              isSales={isSales}
              dealId={dealId}
              companyId={companyId}
              contractId={contractId}
              leadId={leadId}
              hasBenefitsProducts={hasBenefitsProduct}
            />
          ),
          image: BannerImage,
          index: 3,
        };
      case 'commercial-conditions':
        return {
          component: (
            <ComercialConditions
              form={commercialConditionsForm}
              signupType={signupType}
            />
          ),
          form: commercialConditionsForm,
          useNewLayout: true,
          button: {
            text: 'Concluir contratação',
            icon: 'IconCheck' as IconsProps['name'],
          },
          image: bannerUrl,
          index: 4,
        };
      default:
        return {
          component: <Navigate to="/signup/self/create-company" replace />,
        };
    }
  };

  const stepProps = getStep();
  const isLoading = adminInfoLoading || commercialConditionsLoading;

  if (error || step === 'error') {
    return <SignUpErrorPage />;
  }

  if (stepProps.useNewLayout) {
    return (
      <MainContainer>
        <Header ref={refToTop}>
          <LogoFlash />
        </Header>
        <Content>
          {stepProps.component}
          {stepProps.image !== '' && (
            <ImageColumn>
              <Image backgroundUrl={stepProps.image} />
            </ImageColumn>
          )}
        </Content>
        <Divider orientation="horizontal" />
        <Footer>
          {signupType === 'hubspot' && (
            <LinkButton
              variant="primary"
              onClick={onFinishLater}
              disabled={isLoading}
              style={{ alignSelf: 'auto' }}
            >
              Terminar mais tarde
            </LinkButton>
          )}
          <FooterButtons>
            {stepProps.onBack && (
              <Button
                size="large"
                variant="secondary"
                loading={isLoading}
                style={{ width: '250px' }}
                onClick={stepProps.onBack}
              >
                <Icons name="IconArrowLeft" /> Voltar
              </Button>
            )}
            <Button
              size="large"
              variant="primary"
              loading={isLoading}
              disabled={!stepProps.form?.isValid}
              onClick={stepProps.form?.submitForm}
              style={{ width: '250px' }}
            >
              {stepProps.button?.text || 'Continuar'}{' '}
              <Icons name={stepProps.button?.icon || 'IconArrowRight'} />
            </Button>
          </FooterButtons>
        </Footer>
        <SignUpSuccessModal
          open={openSignupSuccessModal}
          onConfirm={onSignupSuccessConfirm}
        />
        <FluxEndModal open={openFluxEndModal} isTrial={!!leadId} />
        <FinishLaterModal
          open={openFinishLaterModal}
          isLastStep={step === 'commercial-conditions'}
          onCancel={() => setOpenFinishLaterModal(false)}
        />
      </MainContainer>
    );
  }

  // TODO: remove after checking with tracking if
  // PhoneAuthentication and PersonalInfo
  // are not used anymore
  return (
    <MainContainer>
      <HeaderContainer ref={refToTop}>
        {step !== 'error' && (
          <SignUpProgressBar
            hasCommercialCondition={hasBenefitsProduct}
            hasPhoneConfirmation={!authenticatedUser}
            loading={userLoading}
            currentStep={stepProps.index!}
          />
        )}
      </HeaderContainer>
      <ContentContainer>
        {stepProps.component}
        {stepProps.image !== '' && (
          <ImageColumn>
            <Image backgroundUrl={stepProps.image!} />
          </ImageColumn>
        )}
      </ContentContainer>
      <FooterContainer>
        <PageFooter />
      </FooterContainer>
      <SignUpSuccessModal
        open={openSignupSuccessModal}
        onConfirm={onSignupSuccessConfirm}
      />
      <FluxEndModal open={openFluxEndModal} isTrial={!!leadId} />
    </MainContainer>
  );
};
